var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { value: _vm.tooltip, "open-on-hover": false, bottom: "" },
      on: { change: _vm.onchanged },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "" }, on: { click: _vm.click } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-content-copy")])],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v("Copied!")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }